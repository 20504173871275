<template>
  <div id="submaps-area">
    <div id="submap-selector">
      <ion-select placeholder="Select floor"
                  interface="action-sheet"
                  :value="selectedSubmap.guid"
                  @ionChange="onSubmapSelected">
        <ion-select-option v-for="submap in submaps" :key="submap.guid"
                           :value="submap.guid">{{submap.label}}</ion-select-option>
      </ion-select>
    </div>
    <div id="submaps-container">
      <div class="submap" v-for="(submap, key) in submaps" :key="key"
           :class="{ selected: selectedSubmap.guid === submap.guid }"
           :id="`submap-${submap.guid}`">
        <img :src="getSubmapImageUrl(accountCodeName, submap.image)"
             class="map-image" :id="`submap-${submap.guid}-img`"
             @load="(event) => onSubmapImgLoad(event, submap.guid)" />
        <img v-for="point in pointsOnThisSubmap" :key="point.guid"
             :src="getMarkerImageUrl(point)" class="marker"
             :class="{ selected: isPointSelected(point), 'in-a-group': isInAGroup(point) }"
             :data-xpct="point.xPct" :data-ypct="point.yPct"
             :style="getIconPosition(point, submapWidth, submapHeight)"
             @click="onPointClick(point.guid, submap.guid)" />
      </div>
      <div class="zoom-buttons">
        <ion-button @click="zoomIn" color="light">
          <ion-icon src="assets/icon/add.svg"></ion-icon>
        </ion-button>
        <ion-button @click="zoomOut" color="light">
          <ion-icon src="assets/icon/remove.svg"></ion-icon>
        </ion-button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import router from '@/router'
import store from "@/store";
import { getIconForFolder } from "@/store/folders";
import { isInAGroup, isPointSelected } from "@/store/points";
import {
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption
} from '@ionic/vue';
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";

const accountCodeName = ref(store.state.accountCodeName)
const points = ref([])
const pointsByGuid = ref({})
const pointsOnThisSubmap = ref([])
const selectedSubmap = ref({})
const submaps = ref([])
const submapWidth = ref(window.innerWidth)
const submapHeight = ref(0)
const submapContainerWidth = window.innerWidth

let currentZoomLevel = 0.0
let submapsContainerElement = null
let submapElement = null
let submapImgElement = null

const getSubmapImageUrl = (accountCodeName, submapImage) => {
  return `https://pointsmap.com/${accountCodeName}/data/photos/${submapImage}`
}
const isOnSubmap = submap => point => submap.guid === point.parentSubmap
const getPointsOnThisSubmap = (points, submap) => points.filter(isOnSubmap(submap))
const getMarkerImageUrl = point => {
  const iconToUse = point.icon ? point.icon : getIconForFolder(point.folder)
  return `https://pointsmap.com/images/icons/points/${iconToUse}`
}
const getIconPosition = (point, submapWidth, submapHeight) => {
  return {
    left: `${point.xPct * submapWidth}px`,
    top: `${point.yPct * submapHeight}px`,
  }
}
const onPointClick = (pointGuid, submapGuid) => {
  const folderGuid = store.state.selectedFolder
  if (folderGuid) {
    router.push({ name: 'point-in-folder-in-submap', params: { pointGuid, folderGuid, submapGuid } })
  } else {
    router.push({ name: 'point-in-submap', params: { pointGuid, submapGuid } })
  }
}

const onSelectedSubmapChange = async () => {
  if (selectedSubmap.value.guid) {
    pointsOnThisSubmap.value = getPointsOnThisSubmap(points.value, selectedSubmap.value)
  } else {
    pointsOnThisSubmap.value = []
  }
}

const showSubmapAtCurrentZoomLevel = () => {
  const viewBounds = submapsContainerElement.getBoundingClientRect()
  const viewWidth = viewBounds.width
  const viewHeight = viewBounds.height
  const pointInViewCenteredInZooming = 0.50; // 0.50 = 50% (i.e. the middle)

  const oldMapWidth = submapImgElement.offsetWidth
  const oldMapHeight = submapImgElement.offsetHeight
  const oldMapLeft = -submapsContainerElement.scrollLeft
  const oldMapTop = -submapsContainerElement.scrollTop

  const mapImageHeightToWidthRatio = oldMapHeight / oldMapWidth

  const horizontalViewPixelCenteredInZooming = viewWidth * pointInViewCenteredInZooming
  const verticalViewPixelCenteredInZooming = viewHeight * pointInViewCenteredInZooming
  const horizontalPointOnMapCenteredInZooming = (horizontalViewPixelCenteredInZooming - oldMapLeft) / oldMapWidth
  const verticalPointOnMapCenteredInZooming = (verticalViewPixelCenteredInZooming - oldMapTop) / oldMapHeight

  const desiredZoomFactor = 1.5 ** currentZoomLevel
  const newMapWidth = submapContainerWidth * desiredZoomFactor
  const newMapHeight = newMapWidth * mapImageHeightToWidthRatio
  submapWidth.value = newMapWidth
  submapElement.style.width = submapWidth.value + 'px'
  submapHeight.value = newMapHeight
  submapElement.style.height = submapHeight.value + 'px'

  const changeInMapWidth = newMapWidth - oldMapWidth
  const changeInMapHeight = newMapHeight - oldMapHeight
  const newMapLeft = oldMapLeft - (horizontalPointOnMapCenteredInZooming * changeInMapWidth)
  const newMapTop = oldMapTop - (verticalPointOnMapCenteredInZooming * changeInMapHeight)

  // Note: scrollTo() uses negative values where our calculations use positive values.
  submapsContainerElement.scrollTo(-newMapLeft, -newMapTop)
}

const updateSubmapDisplay = () => {
  if (!submapElement && selectedSubmap.value.guid) {
    submapElement = document.getElementById(`submap-${selectedSubmap.value.guid}`)
    submapImgElement = document.getElementById(`submap-${selectedSubmap.value.guid}-img`)
  }

  if (submapElement) {
    showSubmapAtCurrentZoomLevel()
  }
}

const onSubmapImgLoad = (event, submapGuid) => {
  if (selectedSubmap.value.guid === submapGuid) {
    updateSubmapDisplay()
  }
}

const zoomTo = newZoomLevel => {
  if (newZoomLevel < 0) {
    newZoomLevel = 0.0
  }
  currentZoomLevel = newZoomLevel
  updateSubmapDisplay()
}

const zoomIn = () => zoomTo(currentZoomLevel + 1)
const zoomOut = () => zoomTo(currentZoomLevel - 1)

export default defineComponent({
  name: "Submap",
  components: {
    IonButton,
    IonIcon,
    IonSelect,
    IonSelectOption,
  },
  mounted() {
    submapsContainerElement = document.getElementById('submaps-container')

    // Force this to be run, since it isn't when re-entering a submap for some reason.
    onSelectedSubmapChange()
  },
  setup() {
    selectedSubmap.value = store.getSubmap(store.state.selectedSubmap) || {}
    submaps.value = store.getSubmapsForPoint(selectedSubmap.value.parentPointGuid)
    store.getPointsByGuidOnceLoaded().then(data => {
      pointsByGuid.value = data
    })

    setTimeout(() => {
      accountCodeName.value = store.state.accountCodeName
    })

    watch(
      () => store.state.selectedSubmap,
      () => {
        selectedSubmap.value = store.getSubmap(store.state.selectedSubmap) || {}
        if (submapElement) {
          submapsContainerElement.scrollTo(0, 0)
          setTimeout(() => zoomTo(0.0))
          submapElement = null
        }
        if (submapImgElement) {
          submapImgElement = null
        }
        submapWidth.value = submapContainerWidth
        submaps.value = store.getSubmapsForPoint(selectedSubmap.value.parentPointGuid)
      }
    )

    watch(
      pointsByGuid,
      () => {
        points.value = Object.values(pointsByGuid.value)
      }
    )

    watch(
      selectedSubmap,
      onSelectedSubmapChange
    )

    watch(
      points,
      () => {
        pointsOnThisSubmap.value = getPointsOnThisSubmap(points.value, selectedSubmap.value)
      }
    )

    watch(
        pointsOnThisSubmap,
        () => {
          console.log(pointsOnThisSubmap.value.length + ' points on this submap')
        }
    )

    const router = useRouter()
    const onSubmapSelected = event => {
      const submapGuid = event.detail.value
      if (submapGuid !== selectedSubmap.value.guid) {
        router.push({ name: 'submap', params: { submapGuid } })
      }
    }

    return {
      accountCodeName,
      getIconPosition,
      getMarkerImageUrl,
      getSubmapImageUrl,
      isInAGroup,
      isPointSelected,
      onPointClick,
      onSubmapImgLoad,
      onSubmapSelected,
      pointsOnThisSubmap,
      selectedSubmap,
      submaps,
      submapHeight,
      submapWidth,
      zoomIn,
      zoomOut,
    }
  },
})
</script>

<style scoped>
.in-a-group {
  display: none;
}

.submap > .map-image {
  height: auto;
  width: 100%;
}

.submap > img.marker {
  height: 18px;
  width: 20px;
  margin-left: -9px;
  margin-top: -10px;
  position: absolute;
  z-index: 2;
}

.submap > img.marker.selected {
  height: 36px;
  width: 40px;
  margin-left: -18px;
  margin-top: -20px;
}

.submap {
  display: none;
  height: 100%;
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
}
.submap.selected {
  display: block;
}

#submaps-area {
  background-color: #fff;
  color: #111;
  position: absolute;
  bottom: 0;
  top: var(--offset-top);
  left: 0;
  right: 0;
  overflow: hidden;
}

#submaps-container {
  height: 100%;
  position: relative;
  overflow: scroll;
  scrollbar-width: none;
}

.zoom-buttons {
  position: fixed;
  bottom: 4px;
  right: 4px;
  z-index: 10;
}
.zoom-buttons > ion-button {
  display: block;
  filter: drop-shadow(
    1px 2px 4px hsl(0deg 0% 0% / 0.25)
  );
}
</style>
